@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Open sans", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  color: #4a4a4a!important;
  background-color: #F5F5F5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sign-pad button {
  border: none;
}

.sign-pad button:hover {
  border: none;
}

.sign-pad div {
  width: 100% !important;
}

.radio-selected span {
  font-weight: bold;
}

.upload-document div {
  margin: 0px !important;
}